<template>
  <div v-if="appStateStore.loading">
    <div id="overlay">
      <div class="flex items-center justify-center w-full h-full">
        <div class="content-loading">
          <img
            src="~/assets/svg/loading.svg"
            alt="loading"
            width="200"
            height="200"
            class="mx-auto"
          >
          <p class="-mt-8 text-white text-center" v-if="typeof appStateStore.loading == 'string'">{{ appStateStore.loading }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useAppStateStore } from '~/stores/appState'

  const appStateStore = useAppStateStore()
</script>

<style scoped>
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000a8;
  z-index: 999;
  border: 0px;
}
</style>